<template>

  <div class="row" ref="testimonialsForm">
    <div class="col-xs-12 col-md-12 col-sm-12">
      <ValidationObserver v-slot="{ handleSubmit }" ref="formValidator">

        <card>
          <div slot="header">
            <h4 class="card-title">
              {{ formTitle }}
            </h4>
          </div>

          <div class="card-body">
            <div class="row">
              <div class="col-md-12 col-sm-12">
                <ValidationProvider
                  vid="name"
                  rules="required"
                  name="The Name"
                  v-slot="{ passed, failed,errors }">
                  <fg-input type="text"
                            :error="failed ? errors[0]: null"
                            label="name"
                            name="name"
                            fou
                            v-model="formData.name">
                  </fg-input>
                </ValidationProvider>
                <ValidationProvider
                  vid="rating"
                  rules="required"
                  name="The Rating"
                  v-slot="{ passed, failed,errors }">
                  <fg-select
                    name="rating"
                    size="large"
                    filterable
                    clearable
                    placeholder="Rating"
                    :error="failed ? errors[0]: null"
                    :input-classes="'select-default'"
                    :label="'Rating'"
                    :list="ratingList"
                    :listItemLabel="'name'"
                    :listItemValue="'value'"
                    v-model="formData.rating">
                  </fg-select>
                </ValidationProvider>
                <ValidationProvider
                  vid="title"
                  rules="required"
                  name="The Title"
                  v-slot="{ passed, failed,errors }">
                  <fg-input type="text"
                            :error="failed ? errors[0]: null"
                            label="title"
                            name="title"
                            fou
                            v-model="formData.title">
                  </fg-input>
                </ValidationProvider>
                <ValidationProvider
                  vid="details"
                  rules=""
                  name="The Details"
                  v-slot="{ passed, failed,errors }">
                  <fg-text type="text"
                           :error="failed ? errors[0]: null"
                           label="Details"
                           name="details"
                           fou
                           v-model="formData.details">
                  </fg-text>
                </ValidationProvider>
                <div class="row">
                  <div class="col-md-6">
                    <ValidationProvider
                      vid="location"
                      rules=""
                      name="The Location"
                      v-slot="{ passed, failed,errors }">
                      <fg-input type="text"
                                :error="failed ? errors[0]: null"
                                label="location"
                                name="location"
                                fou
                                v-model="formData.location">
                      </fg-input>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-6">
                    <label class="card-label">Date</label>
                    <fg-input v-model="formData.date"
                              value-format="YYYY-MM-DD HH:MM:SS"
                              type="datetime-local"
                              placeholder="Chose date"
                    >
                    </fg-input>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group pt-4">
              <label>Is Active</label>&nbsp;
              <l-switch v-model="formData.is_active">
                <i class="fa fa-check" slot="on"></i>
                <i class="fa fa-times" slot="off"></i>
              </l-switch>
            </div>
          </div>
          <div class="card-footer text-right">
            <l-button :disabled="entityNotFoundError || submitting" @click.prevent="handleSubmit(submit)"
                      nativeType="submit"
                      type="info" wide>Submit
            </l-button>
            <l-button @click="$router.push('/testimonials/list')" type="danger" wide>Cancel
            </l-button>
          </div>
        </card>

      </ValidationObserver>

    </div>

  </div>
</template>
<script>
import {extend} from "vee-validate";
import {Option, Select, TableColumn, Tooltip} from 'element-ui';
import FgSelect from "@/components/Inputs/formGroupSelect";
import FormGroupTextArea, {PrimeUploader, PrimeVideoUploader, Switch as LSwitch} from "@/components";
import Editor from '@tinymce/tinymce-vue'
import {mapGetters} from "vuex";
import GeneralDataTable from "@/components/GeneralDataTable";
import draggable from 'vuedraggable';

extend("required", {
  message: "{_field_} is required"
});

export default {

  components: {
    FormGroupTextArea,
    [Option.name]: Option,
    [Select.name]: Select,
    [Tooltip.name]: Tooltip,
    [TableColumn.name]: TableColumn,
    FgSelect,
    LSwitch,
    GeneralDataTable,
    PrimeUploader,
    'editor': Editor,
    draggable
  },
  data() {
    return {
      editMode: false,
      loader: {},
      id: undefined,
      formTitle: "",
      entityNotFoundError: false,
      submitting: false,
      formData: {
        name: "",
        title :"",
        details: "",
        location: "",
        date: "",
        rating: "",
        is_active: true,
      },
      ratingList: [
        {name: '*' ,value: 1},
        {name: '**' ,value: 2},
        {name: '***' ,value: 3},
        {name: '****' ,value: 4},
        {name: '*****' ,value: 5},
      ]
    };
  },
  computed: {
    ...mapGetters({
      mediaInfo: "global/mediaInfo"
    }),
  },
  mounted() {
    this.loader = this.$loading.show({
      container: this.$refs.testimonialsForm
    });

    this.id = this.$route.params['id'];
    if (this.id !== undefined) {
      this.editMode = true;
      this.formTitle = "Edit Testimonial";
      this.getTestimonial();
    } else {
      this.editMode = false;
      this.formTitle = "Add Testimonial";
      this.loader.hide();
    }

  },


  methods: {
    getTestimonial() {
      let data = {
        id: this.id,
      }
      this.axios.post("testimonials/get", data).then((response) => {
        this.formData = response.data;
        this.vueDate();
        this.loader.hide();
      }).catch((error) => {
        if (error.response.status === 404) {
          this.entityNotFoundError = true;
          this.$notify({
            message: "Testimonial Not Found",
            timeout: 2000,
            type: 'danger'
          });
          this.loader.hide();

        } else {
          console.error(error);
        }
      })
    },


    submit() {
      let request;
      let successMessage;
      this.submitting = true;
      this.laravelDate();
      if (this.editMode === true) {
        request = this.axios.put("testimonials/update/" + this.id, this.formData);
        successMessage = "Testimonial Updated Successfully";
      } else {
        request = this.axios.post("testimonials/create", this.formData);
        successMessage = "Testimonial Added Successfully";
      }

      request.then((response) => {
        this.$notify({
          message: successMessage,
          timeout: 1000,
          type: 'success'
        })
        this.$router.push("/testimonials/list");
      }).catch((error) => {
        if (error.response.status === 422) {
          this.$refs.formValidator.setErrors(error.response.data.errors);
        } else {
          console.log(error.response)
        }
      }).finally(() => {
        this.submitting = false;
      })
    },
    vueDate() {
      this.formData.date = (this.formData.date) ? this.formData.date.replace(' ', 'T') : this.formData.date;
    },
    laravelDate() {
      this.formData.date = (this.formData.date) ? this.formData.date.replace('T', ' ') : this.formData.date;
    }
  }
}
</script>

<style>

.extra-fields {
  box-shadow: rgb(235 232 232) 1px 1px 20px 0px, rgb(235 232 232) -1px -1px 20px 0px;
  padding: 0 0 3px;
}

.extra-fields > h4 {
  padding: 20px;
}
</style>
